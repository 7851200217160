<template>
  <el-dialog
    title="Product details"
    :visible.sync="dialogVisible"
    width="1000px"
    :before-close="handleClose"
    :close-on-click-modal="false"
    :fullscreen="isPhone"
  >
    <div
      class="content productDetails"
      v-loading="loading"
      element-loading-text="Loading..."
    >
      <template v-if="details">
        <div class="top">
          <div class="left">
            <div class="img1">
              <el-image
                class="img"
                :src="coverValue"
                alt="点击查看大图"
                :preview-src-list="imageList"
              />
            </div>
            <div class="img2">
              <div class="con">
                <el-image
                  class="img"
                  v-for="(item, i) in imageList"
                  @mouseenter="onMouseEnter(i)"
                  :key="i"
                  :src="item"
                  alt="点击查看大图"
                />
              </div>
            </div>
          </div>
          <div class="right">
            <div class="text1">
              <p><b>Product Name：</b></p>
              <p class="text">{{ details.category }}</p>
            </div>
            <div class="text2">
              <p><b>Suitable for implant platform：</b></p>
              <p class="text">{{ details.name }}</p>
            </div>
            <div class="text3">
              <p><b>Specifications and models：</b></p>
              <div class="text">
                <el-tag
                  v-for="item in details.specifications"
                  :key="item.name"
                  style="margin-right: 10px; margin-bottom: 10px"
                  >{{ item.name }}</el-tag
                >
              </div>
            </div>
          </div>
        </div>
        <div class="con1">
          <p>Product introduction：</p>
          <div v-html="details.content"></div>
        </div>
        <div class="con2">
          <p>Related article：</p>
          <el-link type="primary" @click="linKClick">{{
            details.article
          }}</el-link>
        </div>
      </template>
    </div>

    <span slot="footer" class="dialog-footer" v-if="!isPhone">
      <el-button @click="handleClose" size="small">Off</el-button>
      <!-- <el-button type="primary" @click="dialogVisible = false">确 定</el-button> -->
    </span>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialogVisible: true,
      details: {},
      loading: false,
      isPhone: false,
      imageList: [],
      coverValue: "",
    };
  },
  props: {
    id: [Number, String],
  },
  watch: {},
  async created() {
    if (document.body.clientWidth > 750) {
      this.isPhone = false;
    } else {
      this.isPhone = true;
    }
    await this.getDetails();
  },
  mounted() {
    window.onresize = () => {
      return (() => {
        this.windowWidth = document.body.clientWidth;
        if (document.body.clientWidth > 750) {
          this.isPhone = false;
        } else {
          this.isPhone = true;
        }
      })();
    };
  },
  methods: {
    onMouseEnter(index) {
      this.coverValue = this.imageList[index];
    },
    linKClick() {
      this.$router.push({
        path: "productIntroduction",
        query: {
          id: this.details.article_id,
        },
      });
    },
    handleClose() {
      this.$emit("close");
    },
    getDetails() {
      this.loading = true;
      return this.$axios
        .post("/index/product/detail", {
          id: this.id,
        })
        .then((res) => {
          if (res.code == 1) {
            this.details = res.data;
            this.imageList = [this.details.cover, ...this.details.image];
            this.coverValue = this.details.cover;
            setTimeout(() => {
              this.loading = false;
            }, 500);
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  .top {
    display: flex;
    .left {
      width: 280px;
      height: 350px;
      border: 1px solid #ddd;
      background: #f0eff2;
      flex: none;
      .img1 {
        width: 100%;
        width: 280px;
        height: 280px;
        border-bottom: 1px solid #ddd;
      }
      .img2 {
        width: 280px;
        height: 70px;
        overflow-y: auto;
        .con {
          height: 70px;
          overflow-y: auto;
          display: flex;
          align-items: center;
          padding-right: 5px;
          .img {
            width: 50px;
            height: 50px;
            border: 1px solid #ddd;
            float: left;
            margin-left: 5px;
            cursor: pointer;
            border-radius: 3px;
            flex: none;
          }
        }
      }
    }
    .right {
      width: 68%;
      padding-left: 30px;
      flex: none;
      .text {
        font-size: 20px;
        font-weight: 600;
        margin: 10px 0;
        color: #333;
      }
    }
  }
  .con1 {
    white-space: pre-wrap;
    p {
      font-size: 18px;
      margin: 10px 0;
    }
    div {
      border: 1px solid #ebeef5;
      border-radius: 3px;
      padding: 10px;
    }
  }
  .con2 {
    p {
      font-size: 18px;
      margin: 10px 0;
    }
  }
}
@media (max-width: 750px) {
  .productDetails {
    .top {
      display: block;
      .left {
        width: 100%;
        height: 410px;
        border: 1px solid #ddd;
        background: #f0eff2;
        flex: none;
        .img1 {
          width: 100%;
          width: 100%;
          height: 340px;
          border-bottom: 1px solid #ddd;
          .img {
            width: 100%;
            height: 100%;
          }
        }
        .img2 {
          width: 100%;
          height: 70px;
          overflow-y: auto;
          .con {
            height: 70px;
            overflow-y: auto;
            display: flex;
            align-items: center;
            padding-right: 5px;
            .img {
              width: 50px;
              height: 50px;
              border: 1px solid #ddd;
              float: left;
              margin-left: 5px;
              cursor: pointer;
              border-radius: 3px;
              flex: none;
            }
          }
        }
      }
      .right {
        width: 100%;
        margin-top: 20px;
        padding-left: 0;
        .text {
          font-size: 17px;
        }
      }
    }
  }
}
</style>
